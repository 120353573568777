<template>
    <main>
        <div class="container-fluid">
            <h1>Status o/</h1>
            <ul>
                <li>
                    renderComponent: {{renderComponent}}
                </li>
                <li>
                    networkState: {{networkState}}
                </li>
                <li>
                    onLine: {{onLine}}
                </li>
                <li>
                    imageDownloads: {{imageDownloads}}
                </li>
                <li>
                    imageDownloadsVersion: {{imageDownloadsVersion}}
                </li>
                <li>
                    isCordovaApp: {{isCordovaApp}}
                </li>
                <li>
                    DachziegelDataVersion: {{DachziegelDataVersion}}
                </li>
                <li>
                    DachziegelAppVersion: {{DachziegelAppVersion}}
                </li>
                <li>
                    Bilder:<br>
                    <span v-for="objImg in DachziegelData.data.imgs.img">
                        <img :src="objImg.url" style="max-width: 50px" alt="">
                    </span>
                </li>
                <li>
                    DachziegelData:
                    <pre>{{DachziegelData}}</pre>
                </li>
            </ul>
        </div>
    </main>
</template>
<script>
    import {DachziegelDataMixin} from "../mixins/DachziegelDataMixin";

    export default {
        name: "Status",
        mixins: [DachziegelDataMixin],
        mounted : function () {
            this.$DKFuctions.headerShowGoBackShow()
        },
    }
</script>
